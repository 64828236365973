exports.components = {
  "component---src-layouts-post-tsx-content-file-path-home-runner-work-personal-website-personal-website-content-posts-test-mdx": () => import("./../../../src/layouts/post.tsx?__contentFilePath=/home/runner/work/personal-website/personal-website/content/posts/test.mdx" /* webpackChunkName: "component---src-layouts-post-tsx-content-file-path-home-runner-work-personal-website-personal-website-content-posts-test-mdx" */),
  "component---src-layouts-presentation-tsx-content-file-path-home-runner-work-personal-website-personal-website-content-presentations-defense-talk-mdx": () => import("./../../../src/layouts/presentation.tsx?__contentFilePath=/home/runner/work/personal-website/personal-website/content/presentations/defense/talk.mdx" /* webpackChunkName: "component---src-layouts-presentation-tsx-content-file-path-home-runner-work-personal-website-personal-website-content-presentations-defense-talk-mdx" */),
  "component---src-layouts-presentation-tsx-content-file-path-home-runner-work-personal-website-personal-website-content-presentations-generative-inference-talk-mdx": () => import("./../../../src/layouts/presentation.tsx?__contentFilePath=/home/runner/work/personal-website/personal-website/content/presentations/generative-inference/talk.mdx" /* webpackChunkName: "component---src-layouts-presentation-tsx-content-file-path-home-runner-work-personal-website-personal-website-content-presentations-generative-inference-talk-mdx" */),
  "component---src-pages-books-tsx": () => import("./../../../src/pages/books.tsx" /* webpackChunkName: "component---src-pages-books-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-posts-tsx": () => import("./../../../src/pages/posts.tsx" /* webpackChunkName: "component---src-pages-posts-tsx" */),
  "component---src-pages-presentations-tsx": () => import("./../../../src/pages/presentations.tsx" /* webpackChunkName: "component---src-pages-presentations-tsx" */)
}

